import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faPlayCircle, faEnvelope, faChevronRight, faEye, faCheck } from "@fortawesome/free-solid-svg-icons"

export default class Page extends React.Component {
  render() {
    return (
      <Layout container={true} hasDarkColorScheme={true} isDark={true}>
        <SEO title="Kosten sparen durch Generalüberholung für Ihre Webseite"></SEO>
        <div className="loosepage text-center">
          <h1>Generalüberholung</h1>
          <p style={{ maxWidth: 500, margin: "auto" }}>Modernisierung bestehender Webseiten. <span className="muted">Vermeiden Sie Sicherheitslücken und Google Rangverluste durch veraltete Standards und starten Sie mit einer modernen und benutzerfreundlichen Webseite durch.</span></p>
        </div>
        <div style={{ margin: "auto", maxWidth: "800px" }}>
            <Img style={{ borderRadius: "7px" }}                     src={require("../../images/services/relaunch.jpg")}/>
        </div>
        <div className="text-center" style={{margin:20}}>
          <Link className="btn btn-primary-outline" to="/portfolio" target="_blank"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> Beispiel Webseiten</Link>
          <Link className="btn btn-primary-outline" to="/mehr-erfahren">Mehr erfahren <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></Link>
        </div>
        <br/>
        <div className="okbox">
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Selbstadministration mit WordPress<span className="supermuted">*</span></div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Design in Anlehnung an bestehende Webseite<span className="supermuted">*</span></div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Modernes Kontaktformular<span className="supermuted">*</span></div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Kostenlose Nachregistrierung bei Google<span className="supermuted">*</span></div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Suchmaschinenoptimierung für Google<span className="supermuted">*</span></div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Newsletter/Kalender/Anmeldesystem<span className="supermuted">*</span></div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Entwicklung WordPress - Erweiterungen<span className="supermuted">*</span></div>
          <span className="muted" style={{ fontSize: ".7em" }}>* auf Kundenwunsch, kann den genannten Basispreis übersteigen</span>
        </div>
        <br />
        <br />
        <br />
      </Layout >
    )
  }
}

